<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-if="this.params.data.status !=2">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
    <div class="delete_employee" :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-else-if="this.params.data.status ==2">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
       this.$router.push("/story/edit/" + this.params.data.media_id).catch(() => {})
    },
     confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You Are About To Delete Media Title Of "${this.params.data.title}"`,
        accept: this.deleteMedia,
        acceptText: 'Confirm'
      })
    },
    deleteMedia () {
      this.$vs.loading()
      this.$store.dispatch('media/deleteMedia', this.params.data.media_id).then(() => { this.$vs.loading.close()
                this.showDeleteSuccess()
                this.$store.dispatch('media/fetchMedias').then(() => { this.$vs.loading.close() })
       }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
       notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Story Deleted',
        text: 'Story Removed Successfully...!!!'
      })
    }
  }
}
</script>
<style>
button.vs-component.vs-button.vs-dialog-accept-button.vs-button-danger.vs-button-filled {
    width: -webkit-fill-available;
    height: 49px;
}
</style>