<template>
    <span>{{ this.category_name(params.value) }}</span>
</template>

<script>
export default {
  name: 'CellRendererCategory',
     methods: {
      category_name (categoryId) {
        const categoryName = this.$store.getters['category/getCategoryName'](categoryId)
        return categoryName
    },  
  },
}
</script>
