<template>
    <span>{{ this.slno(params.value) }}</span>
</template>

<script>
export default {
  name: 'CellRendererSlno',
     methods: {
      slno (slno) {
        return slno 
    },  
  }
}
</script>
