<template>
    <span>{{ this.category_name(params.value) }}</span>
</template>

<script>
export default {
  name: 'CellRendererSubCategory',
     methods: {
      category_name (categoryId) {
      return this.$store.getters['category/getCategoryName'](categoryId)
    },  
  }
}
</script>
