<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="page-user-list">

    <div class="card_margin-bottom">
    <div class="vx-card p-6">
     <div class="flex flex-wrap items-center overlap_field">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
             
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      
        <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4  add_new">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="$router.push('/story/new').catch(() => {})">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
      <div class="refresh_data">
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" >
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>
              <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
          
      </div>
      </div>
      </div>
   

    <div class="vx-card p-6">

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table data-table-width"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="medias"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

// Store Module
//import moduleMedia from '@/store/media/moduleMedia.js'

// Cell Renderer
import CellRendererStatus       from './MeidaCellRenderer/MeidaCellRendererStatus.vue'
import CellRendererVerified     from './MeidaCellRenderer/MeidaCellRendererVerified.vue'
import CellRendererActions      from './MeidaCellRenderer/MeidaCellRendererActions.vue'
import CellRendererCategory     from './MeidaCellRenderer/MeidaCellRendererCategory.vue'
import CellRendererSubCategory  from './MeidaCellRenderer/MeidaCellRendererSubCategory.vue'
import CellRendererSlno         from './MeidaCellRenderer/MeidaCellRendererSlno.vue'
import CellRendererDate         from './MeidaCellRenderer/MeidaCellRendererDate.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererCategory,
    CellRendererSubCategory,
    CellRendererSlno,
    CellRendererDate
  },
  data () {
    return {
      searchQuery: '',
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'S.NO',
          field: 'media_id',
          width: 85,
          filter: false,
          cellRendererFramework: 'CellRendererSlno'
        },
        {
          headerName: 'Title',
          field: 'title',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Category',
          field: 'category_name',
          filter: true,
          width: 140,
         // cellRendererFramework: 'CellRendererCategory'
        },
        {
          headerName: 'SubCategory',
          field: 'sub_category_name',
          filter: true,
          width: 160,
         // cellRendererFramework: 'CellRendererSubCategory'
        },
        {
          headerName: 'Date',
          field: 'created_at',
          filter: false,
          width: 180,
          cellRendererFramework: 'CellRendererDate'
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: false,
          width: 140,
          cellRendererFramework: 'CellRendererStatus'

        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 120,
          cellRendererFramework: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
        CellRendererCategory,
        CellRendererSubCategory,
        CellRendererSlno,
        CellRendererDate
      }
    }
  },
  computed: {
     categories ()     { 
      return this.$store.getters['category/getActiveCategories']      
    },
    medias () {
      return this.$store.getters['media/queriedMedias'] 
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
      refreshData () {
      this.$vs.loading()
      this.$store.dispatch('media/fetchMedias').then(() => { this.$vs.loading.close() })
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    if (this.categories.length === 0) {
      this.$vs.loading()
      this.$store.dispatch('category/fetchCategories').then(() => { this.$vs.loading.close() })
    }
   if(this.medias.length === 0){
      this.$vs.loading()
      this.$store.dispatch('media/fetchMedias').then(() => { this.$vs.loading.close() })
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.ag-floating-filter-button {
    display: none;
}
.add_new {
    position: absolute;
}
.refresh_data {
    position: absolute;
    left: 181px;
}
.vs-input--input {
    border-radius: 16px;
}
</style>
